import { template as template_a8c6ea2d917f4c4cbf4bc5fb7841887a } from "@ember/template-compiler";
const FKControlMenuContainer = template_a8c6ea2d917f4c4cbf4bc5fb7841887a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
