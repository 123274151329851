import { template as template_29e4607f14ac42bfa0fc273c0943c922 } from "@ember/template-compiler";
const FKLabel = template_29e4607f14ac42bfa0fc273c0943c922(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
