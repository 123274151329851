import { template as template_0535efdbc51442a2b3e150d3fd70e2c6 } from "@ember/template-compiler";
const FKText = template_0535efdbc51442a2b3e150d3fd70e2c6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
