import { template as template_5a0b49a289db49f3b156168c674ea0a1 } from "@ember/template-compiler";
const WelcomeHeader = template_5a0b49a289db49f3b156168c674ea0a1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
