import { template as template_db2f8e5fdfb34911aeb02d6530d2e077 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_db2f8e5fdfb34911aeb02d6530d2e077(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
