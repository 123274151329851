import { template as template_18da68d723f24da7824209c5a5fc648d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_18da68d723f24da7824209c5a5fc648d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
